export function isInternalNavigation(url: string): boolean {
  try {
    const windowHostName = window.location.hostname;
    const urlHostName = new URL(url).hostname;
    return windowHostName === urlHostName;
  } catch (error) {
    console.log(error);
  }
  return true;
}
