import React, { FC } from "react";
import { MDXProvider } from "@mdx-js/react";
import PreCode from "./pre-code";
import { isInternalNavigation } from "../../utility/utility";
import type { MDXComponents } from "mdx/types.js";

const components: MDXComponents = {
  a: ({ href, ...props }) => {
    const isInternal = href ? isInternalNavigation(href) : false;
    return (
      <a
        {...props}
        href={href}
        className="link"
        target={isInternal ? "" : "_blank"}
      />
    );
  },
  code: ({ className, ...props }) => {
    const codeClassName = className || "";
    if (codeClassName.includes("language-")) {
      return <code className={className} {...props} />;
    } else {
      return (
        <code
          className={`bg-teal-800 rounded px-1 py-0.5 opacity-80 ${className}`}
          {...props}
        />
      );
    }
  },
  pre: (props) => <PreCode {...props} />,
  wrapper: ({ children }) => <>{children}</>,
};

interface WrapRootElementProps {
  element: React.ReactNode;
}

export const wrapRootElement: FC<WrapRootElementProps> = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
