import React, { useState } from "react";
import Highlight, { defaultProps, Language } from "prism-react-renderer";
// @ts-ignore
import Prism from 'prism-react-renderer/prism'
import theme from "prism-react-renderer/themes/nightOwl";
import rangeParser from "parse-numeric-range";
(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-kotlin");
require("prismjs/components/prism-java");

const languageMapping: Record<string, string> = {
  java: "Java",
  kt: "Kotlin",
  sh: "Bash",
  bash: "Bash",
  python: "Python",
  http: "HTTP",
};

function extractCodeMetaString(className: string): string[] {
  return className.split(":");
}

function getLang(metadata: string[]): string {
  return (metadata[0] || "").replace(/language-/, "");
}

function getFileName(metadatas: string[]): string {
  const key = "filename=";
  const fileEntry = metadatas.find(metadata => metadata.startsWith(key)) || "";
  return fileEntry.replace(key, "");
}

function getHighlightedLineRange(metadatas: string[]): Set<number> {
  const rangeEntry = metadatas.find(
    metadata => metadata.startsWith("{") && metadata.endsWith("}")
  ) || "";
  const range = rangeEntry.replace(/{/, "").replace(/}/, "");
  const arrayRange = rangeParser(range).map(num => num - 1);
  return new Set(arrayRange);
}

function copyToClipboard(str: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str).then(
      () => console.log("Copying to clipboard was successful!"),
      err => console.error("Could not copy text: ", err)
    );
  }
}

interface PreCodeProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLPreElement>, HTMLPreElement>{

}

const PreCode: React.FC<PreCodeProps> = (props) => {
  // @ts-ignore
  const className = props.children?.props.className || "";
  const metadata = extractCodeMetaString(className);
  // @ts-ignore
  const code = props.children?.props.children.trim();
  const language = getLang(metadata) as Language;
  const file = getFileName(metadata);
  const highlight = getHighlightedLineRange(metadata);
  const mappedlanguage = languageMapping[language.toLowerCase()] || language.toUpperCase();
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className="rounded-md mt-5" style={{ backgroundColor: "rgb(1, 22, 39)" }}>
      <div className="flex pl-6 pr-1 mb-3">
        <div className="bg-slate-100 px-3 py-2 rounded-b-lg text-black font-extrabold prose">
          {mappedlanguage}
        </div>
        <div className="px-6 flex flex-1 items-center italic opacity-50 truncate">
          {file && file}
        </div>
        <div className="px-2 flex items-center justify-right">
          <button
            className="bg-emerald-800 px-3 py-0.5 rounded-md text-slate-300 mt-0"
            onClick={() => {
              copyToClipboard(code);
              setIsCopied(true);
              setTimeout(() => setIsCopied(false), 1000);
            }}
          >
            {isCopied ? "🎉 Copied!" : "Copy"}
          </button>
        </div>
      </div>
      <div className="overflow-auto">
        <Highlight
          {...defaultProps}
          code={code}
          language={language}
          theme={theme}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre
              className={`${className} rounded-md px-0 float-left min-w-full pt-0 mt-0 pb-0`}
              style={style}
            >
              {tokens.map((line, i) => (
                <div
                  {...getLineProps({ line, key: i })}
                  className={highlight.has(i) ? `bg-sky-900 border-l-4 border-sky-500 px-6` : `px-6`}
                >
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      </div>
    </div>
  );
}

export default PreCode;
